import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Divider from "../../../../../components/divider"
import EvaluationStacked from "../../../../../components/evaluation-stacked"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import Grid from "../../../../../components/grid"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import SolutionsStacked from "../../../../../components/solutions-stacked"
import Stack from "../../../../../components/stack"

const Page = () => {
  const { getTask, answerStatusForArray, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const taskProps = {
    courseId: "versteckte-ordnung",
    chapterId: "02-ordnung",
    taskId: "inventur",
  }
  const answers = answerStatusForArray(taskProps)
  const statistics = getStatsForTaskWithArray(taskProps)
  const solutions = getTask(taskProps).solution

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/versteckte-ordnung/02-ordnung/zusammenfassung"
        />
      }
    >
      <Seo title="Ottos Mops" />
      <Stack>
        <FeedbackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Grid columns={[1, 2]}>
          <EvaluationStacked answers={answers} />
          <SolutionsStacked solutions={solutions} />
        </Grid>
        <Divider size={4} />
        <Grid columns={[1, 2]} reverse={[false, true]}>
          <Stack>
            <Paragraph>
              Jede Strophe von Günter Eichs Gedicht besteht aus 4 Versen.
            </Paragraph>
            <Paragraph>
              Darin werden viele Dinge aufgelistet. Das erklärt auch den Titel
              des Gedichts. Bei einer Inventur werden im Supermarkt alle Artikel
              gezählt, die in den Regalen stehen
            </Paragraph>
          </Stack>
          <PaperStack>
            <Poem size={[3, 3, 4]}>
              <p>Dies ist meine Mütze,</p>
              <p>dies ist mein Mantel,</p>
              <p>hier mein Rasierzeug</p>
              <p>im Beutel aus Leinen.</p>

              <br />
              <p>Konservenbüchse:</p>
              <p>Mein Teller, mein Becher,</p>
              <p>ich hab in das Weißblech</p>
              <p>den Namen geritzt.</p>

              <br />
              <p>Geritzt hier mit diesem</p>
              <p>kostbaren Nagel,</p>
              <p>den vor begehrlichen</p>
              <p>Augen ich berge.</p>

              <br />
              <p>Im Brotbeutel sind</p>
              <p>ein Paar wollene Socken</p>
              <p>und einiges, was ich</p>
              <p>niemand verrate,</p>

              <br />
              <p>so dient es als Kissen</p>
              <p>nachts meinem Kopf.</p>
              <p>Die Pappe hier liegt</p>
              <p>zwischen mir und der Erde.</p>

              <br />
              <p>Die Bleistiftmine</p>
              <p>lieb ich am meisten:</p>
              <p>Tags schreibt sie mir Verse,</p>
              <p>die nachts ich erdacht.</p>

              <br />
              <p>Dies ist mein Notizbuch,</p>
              <p>dies meine Zeltbahn,</p>
              <p>dies ist mein Handtuch,</p>
              <p>dies ist mein Zwirn.</p>
            </Poem>
          </PaperStack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
